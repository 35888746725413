<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
      <ayl-main title="基本信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
            <el-form-item label="用户账号" prop="username">
              <el-input v-model.trim="form.username" :maxlength="64" placeholder="请输入用户账号"></el-input>
            </el-form-item>
            <el-form-item label="用户名称" prop="nickName">
              <el-input v-model.trim="form.nickName" :maxlength="32" placeholder="请输入用户名称"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="phone">
              <el-input v-model.trim="form.phone" :maxlength="11" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" placeholder="请选择">
                <el-option v-for="(item, idx) in $enums.userList.list" :key="idx" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="用户级别" prop="userLevel">
              <el-select v-model="form.userLevel" placeholder="请选择">
                <el-option v-for="(item, idx) in $enums.userLevel.list" :key="idx" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="所属公司" prop="companyId">
              <el-cascader v-model="form.companyId" :options="addUser" :show-all-levels="false" :props="props"
                collapse-tags ref="tree" @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="角色分配" prop="roleNameList">
              <el-select v-model="form.roleNameList" placeholder="请选择" multiple collapse-tags>
                <el-option v-for="(item, idx) in form.roleList" :key="idx" :label="item.roleName"
                  :disabled="item.checked" :value="item.roleIdentity" />
              </el-select>
            </el-form-item>
            <el-form-item label="用户类型" prop="userType">
              <el-select v-model="form.userType" placeholder="请选择">
                <el-option v-for="(item, idx) in form.userTypeList" :key="idx" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="禁止修改密码" prop="allowChangePassword" style="padding-right: 174px">
              <el-radio-group v-model="form.allowChangePassword">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </ayl-main>
      <ayl-main title="其他信息(选填)" :isCollapse="false">
        <div slot="main" class="p24px">
          <el-form :model="form" :rules="rules" :inline="true" label-position="left" label-width="108px">
            <el-form-item label="邮箱">
              <el-input v-model.trim="form.email" :maxlength="64" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="" style="visibility:hidden">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" :maxlength="64" placeholder="请输入内容"
                v-model.trim="form.remark">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </ayl-main>
      <div class="tac" style="margin-top: 40px">
        <el-button type="primary" @click="submitForms" v-if="allowAdd === true" :loading="submitLoading"
          :disabled="isDisable">确认</el-button>
        <el-button type="info" v-if="allowAdd === false">确认</el-button>
        <el-button style="margin-left: 30px"
          @click="$router.push('/sanitation-system-management/role-manage/role-manage')">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    async
  } from 'q';
  export default {
    data() {
      return {
        isDisable: false,
        nav: [{
          name: "编辑",
          path: ""
        }, ],
        props: {
          expandTrigger: 'hover',
          checkStrictly: true
        },
        addUser: [],
        allowAdd: null, //是否具有权限新增用户
        form: {
          userIdentity: this.$route.query.userIdentity, //用户标识符
          roleNameList: this.$route.query.roleList, // 角色分配value值
          userLevel: this.$route.query.userLevel, //用户级别
          phone: this.$route.query.phone, //电话号码
          nickName: this.$route.query.nickName, //用户名称
          companyId: this.$route.query.companyIdList, //所属公司value值
          allowChangePassword: this.$route.query.allowChangePassword, //是否禁止修改密码
          userType: this.$route.query.userType, //用户类型
          email: this.$route.query.email, //邮箱
          username: this.$route.query.username, //用户账号
          status: this.$route.query.status, //状态
          remark: this.$route.query.remark, //备注
          roleList: [], //初始化时通过传过来的公司信息查询到所有角色列表
          userTypeList: null, //初始化用户类型
        },

        rules: {
          username: [{
              required: true,
              message: '用户账号不能为空',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 64,
              required: true,
              message: '请正确输入1-64位的用户账号',
              trigger: 'blur'
            },
            {
              validator: this.$validator.isContainBlank
            },
          ],
          nickName: [{
              required: true,
              message: '用户名称不能为空',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 32,
              required: true,
              message: '请正确输入1-32位的用户名称',
              trigger: 'blur'
            },
            {
              validator: this.$validator.isContainBlank
            },
          ],
          phone: [{
            required: true,
            validator: this.$validator.validatePhone,
            trigger: 'blur'
          }, {
            validator: this.$validator.isContainBlank
          }],
          status: [{
            required: true,
            message: '状态不能为空',
            trigger: 'change'
          }],
          userLevel: [{
            required: true,
            message: '请选择级别',
            trigger: 'change'
          }],
          companyId: [{
            required: true,
            message: '请选择公司',
            trigger: 'change'
          }, ],
          roleNameList: [{
            required: true,
            message: '请分配角色',
            trigger: 'change'
          }, ],
          userType: [{
            required: true,
            message: '请选择用户类型',
            trigger: 'change'
          }],
          allowChangePassword: [{
            required: true,
            message: '请选择活动资源',
            trigger: 'change'
          }],
        },

        submitLoading: false
      }
    },
    methods: {
      async handleChange(node) {
        let roleData = []
        this.form.roleNameList = []
        this.form.userType = null
        this.form.permissionList = node
        //根据选择的公司确定接下来的操作
        let size = this.form.permissionList.length
        this.companyId = this.form.permissionList[size - 1]
        //console.log(this.form.userIdentity)
        roleData = await this.$api.getListHandle({
          userIdentity: this.form.userIdentity,
          companyId: this.form.permissionList[size - 1]
        })
        this.form.roleList = roleData.roleList //角色管理
        this.form.userTypeList = roleData.userTypeList //用户类型
        this.allowAdd = roleData.allowAdd
        if (this.allowAdd === true) {
          return this.allowAdd
        } else {
          this.$message({
            message: '同一个公司只能有一个主账号',
            type: 'error'
          })
          return this.allowAdd
        }
        for (let i in roleData.roleList) {
          if (roleData.roleList[i].checked === true) {
            this.form.roleNameList.push(roleData.roleList[i].roleNameList)
          } else {
            return this.form.roleNameList
          }
        }
      },
      async submitInsurance(val) {
        let checkEmail = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        if ((this.form.email !== undefined && this.form.email !== '') && !checkEmail.test(this.form.email)) {
          this.$message({
            message: '请输入正确邮箱格式',
            type: 'error'
          })
          //this.form.email = ''
        } else {
          if (this.form.userIdentity !== '' && this.form.username !== '' && this.form.nickName !== '' && this.form
            .phone !== '' && this.companyId !== '' && this.form.userTypeLists !== '' && this.form.userLevel !== '' &&
            this.form.allowChangePassword !== '' && this.form.roleNameList.length !== 0 && this.form.userType !== ''
            ) {
            this.submitLoading = true
            try {
              await this.$api.updateUser({
                userInfo: {
                  userIdentity: this.form.userIdentity,
                  username: this.form.username,
                  nickName: this.form.nickName,
                  phone: this.form.phone,
                  email: this.form.email,
                  status: this.form.status,
                  companyId: this.companyId,
                  userType: this.form.userTypeLists,
                  userLevel: this.form.userLevel,
                  allowChangePassword: this.form.allowChangePassword,
                  roleList: this.form.roleNameList,
                  userType: this.form.userType,
                  remark: this.form.remark,
                }
              })
              await this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              })
            } catch (e) {
              if (e === '用户名已存在') {
                return
              }
              //console.log(e)
            }
            this.submitLoading = false
            await this.$router.push('/sanitation-system-management/role-manage/role-manage')
            this.isDisable = true
          } else {
            await this.$message({
              message: '修改失败,请输入必填字段',
              type: 'error'
            })
          }
        }

        setTimeout(() => {
          this.isDisable = false
        }, 3000)
      },
      submitForms() {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.submitInsurance()
          } catch (e) {}
          this.submitLoading = false
        })
      },
    },
    async mounted() {
      this.addUser = await this.$api.getListCompany({})
      // console.log(this.addUser)
      this.form.roleIdentity = []
      let roleData = []
      this.form.permissionList = this.$route.query.companyIdList
      //console.log(this.form.permissionList)

      //根据选择的公司确定接下来的操作
      let size = this.form.permissionList.length
      this.companyId = this.form.permissionList[size - 1]
      roleData = await this.$api.getListHandle({
        userIdentity: this.form.userIdentity,
        companyId: this.form.permissionList[size - 1]
      })
      this.form.roleList = roleData.roleList
      // console.log('初始化角色权限' + roleData.roleList)

      //用户类型
      this.form.userTypeList = roleData.userTypeList
      // console.log('初始化用户类型'+ roleData.userTypeList)
      //初始化时判断是否具有权限新增用户 
      this.allowAdd = roleData.allowAdd
      for (let i in roleData.roleList) {
        if (roleData.roleList[i].checked === true) {
          this.form.roleIdentity.push(roleData.roleList[i].roleIdentity)
        } else {
          return this.form.roleIdentity
        }
      }
    }
  }
</script>
<style lang='sass' scoped>
/deep/.el-button--primary 
  background-color: rgba(26,188,156,1)
  border-color: rgba(26,188,156,1)
</style>